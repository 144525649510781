












import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ErrorView extends Vue {
  private message: string = '';

  private mounted() {
    this.mount();
  }

  private mount() {
    this.message = this.$route.query['message'] as string;
  }

  @Watch('$route', { immediate: true, deep: true })
  private onUrlChange(val: any, oldVal: any) {
    this.mount();
  }
}
